<template>
    <div>
        <!-- Form Modal -->
    <b-modal
        id="modal-season-form"
        ref="myModal"
        centered
        no-close-on-backdrop
        no-close-on-esc
        @ok="submit"
        size="md"
    >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="shipment.id === null">Register Shipment</h5>
                <h5 v-if="shipment.id !== null">Edit Shipment: {{ shipment.number }}</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <!-- <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button> -->
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>


            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <div>

                    <form-wizard
                        color="#7367F0"
                        :title="null"
                        :subtitle="null"
                        finish-button-text="Finish"
                        back-button-text="<< Back"
                        @on-complete="handleUpdate"
                    >
                        <!-- account detail tab -->
                        <tab-content
                        >
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="instruction" label="Instruction Number">
                                        <validation-provider #default="{ errors }" name="Instruction Number" rules="">
                                            <b-form-input
                                                id="instruction"
                                                name="instruction"
                                                v-model="shipment.instruction"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.instruction">{{ serverErrors.instruction[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="number" label="Shipment Number">
                                        <validation-provider #default="{ errors }" name="Shipment Number" rules="">
                                            <b-form-input
                                                id="number"
                                                name="number"
                                                v-model="shipment.number"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="grade" label="Shipping Grade">
                                        <validation-provider #default="{ errors }" name="Shipment Number" rules="">
                                            <b-form-input
                                                id="grade"
                                                name="grade"
                                                v-model="shipment.grade"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.grade">{{ serverErrors.grade[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="internalNumber" label="Internal Shipment#">
                                        <validation-provider #default="{ errors }" name="Internal Shipment#" rules="">
                                            <b-form-input
                                                id="internalNumber"
                                                name="internalNumber"
                                                v-model="shipment.internalNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.internalNumber">{{ serverErrors.internalNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="poNumber" label="PO Number">
                                        <validation-provider #default="{ errors }" name="PO Number" rules="">
                                            <b-form-input
                                                id="poNumber"
                                                name="poNumber"
                                                v-model="shipment.poNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.poNumber">{{ serverErrors.poNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="line" label="Shipping Line">
                                        <validation-provider #default="{ errors }" name="Shipping Line" rules="">
                                            <b-form-input
                                                id="line"
                                                name="line"
                                                v-model="shipment.line"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.line">{{ serverErrors.line[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="mopiNumber" label="MoPI Number">
                                        <validation-provider #default="{ errors }" name="MoPI Number" rules="">
                                            <b-form-input
                                                id="mopiNumber"
                                                name="mopiNumber"
                                                v-model="shipment.mopiNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.mopiNumber">{{ serverErrors.mopiNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="contract" label="Contract Number">
                                        <validation-provider #default="{ errors }" name="Contract Number" rules="">
                                            <b-form-input
                                                id="contract"
                                                name="contract"
                                                v-model="shipment.contract"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.contract">{{ serverErrors.contract[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="batch" label="Batch Number">
                                        <validation-provider #default="{ errors }" name="Batch Number" rules="">
                                            <b-form-input
                                                id="batch"
                                                name="batch"
                                                v-model="shipment.batch"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.batch">{{ serverErrors.batch[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group label-for="description" label="Description of Goods">
                                        <validation-provider #default="{ errors }" name="Batch Number" rules="">
                                            <b-form-textarea
                                                id="description"
                                                name="description"
                                                v-model="shipment.description"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                rows="3"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.description">{{ serverErrors.description[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </tab-content>

                        <!-- page 2 detail tab -->
                        <tab-content
                        >
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="botany" label="Botanical Name">
                                        <validation-provider #default="{ errors }" name="Botanical Name" rules="">
                                            <b-form-input
                                                id="botany"
                                                name="botany"
                                                v-model="shipment.botany"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.botany">{{ serverErrors.botany[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <label for="fumigation_id"><u>Fumigator</u></label>
                                    <b-form-group>
                                        <validation-provider #default="{ errors }" name="Fumigator" rules="">
                                            <b-form-input
                                                id="fumigation_id"
                                                name="fumigation_id"
                                                v-model="shipment.fumigation_id"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.fumigation_id">{{ serverErrors.fumigation_id[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <label>Shipper</label>
                                    <b-form-select
                                        size="sm"
                                        v-model="shipment.shipper_id"
                                    >
                                        <b-form-select-option
                                            v-for="company in companies"
                                            :key="company.id"
                                            :value="company.id"
                                        >
                                            {{ company.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.shipper_id">{{ serverErrors.shipper_id[0] }}</small>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <label>Consignee</label>
                                    <b-form-select
                                        size="sm"
                                        v-model="shipment.consignee_id"
                                    >
                                        <b-form-select-option
                                            v-for="company in companies"
                                            :key="company.id"
                                            :value="company.id"
                                        >
                                            {{ company.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.consignee_id">{{ serverErrors.consignee_id[0] }}</small>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <label for="source_id">Loading Port</label>
                                    <b-form-select
                                        id="source_id"
                                        name="source_id"
                                        size="sm"
                                        v-model="shipment.source_id"
                                    >
                                        <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.code.toUpperCase() }}
                                        </b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="!port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.source_id">{{ serverErrors.source_id[0] }}</small>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <label for="destination_id">Destination Port</label>
                                    <b-form-select
                                        id="destination_id"
                                        name="destination_id"
                                        size="sm"
                                        v-model="shipment.destination_id"
                                    >
                                        <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.code.toUpperCase() }}
                                        </b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="!port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.destination_id">{{ serverErrors.destination_id[0] }}</small>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="vessel" label="Vessel">
                                        <validation-provider #default="{ errors }" name="Vessel" rules="">
                                            <b-form-input
                                                id="vessel"
                                                name="vessel"
                                                v-model="shipment.vessel"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.vessel">{{ serverErrors.vessel[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="voyage" label="Voyage">
                                        <validation-provider #default="{ errors }" name="Voyage" rules="">
                                            <b-form-input
                                                id="voyage"
                                                name="voyage"
                                                v-model="shipment.voyage"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.voyage">{{ serverErrors.voyage[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="etd" label="ETD">
                                        <validation-provider #default="{ errors }" name="ETD" rules="">
                                            <b-form-datepicker
                                                id="etd"
                                                name="etd"
                                                v-model="shipment.etd"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.etd">{{ serverErrors.etd[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="eta" label="ETA">
                                        <validation-provider #default="{ errors }" name="ETA" rules="">
                                            <b-form-datepicker
                                                id="eta"
                                                name="eta"
                                                v-model="shipment.eta"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.eta">{{ serverErrors.eta[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="revisedEta" label="Revised ETA">
                                        <validation-provider #default="{ errors }" name="Revised ETA" rules="">
                                            <b-form-datepicker
                                                id="revisedEta"
                                                name="revisedEta"
                                                v-model="shipment.revisedEta"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.revisedEta">{{ serverErrors.revisedEta[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </tab-content>

                        <!-- page 3 detail tab -->
                        <tab-content
                        >
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="hsCode" label="HS Code">
                                        <validation-provider #default="{ errors }" name="HS Code" rules="">
                                            <b-form-input
                                                id="hsCode"
                                                name="hsCode"
                                                v-model="shipment.hsCode"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.hsCode">{{ serverErrors.hsCode[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="tare" label="Standard Tare">
                                        <validation-provider #default="{ errors }" name="Standard Tare" rules="">
                                            <b-form-input
                                                id="tare"
                                                name="tare"
                                                v-model="shipment.tare"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                type="number"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.tare">{{ serverErrors.tare[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="invoice" label="Invoice Number">
                                        <validation-provider #default="{ errors }" name="Invoice Number" rules="">
                                            <b-form-input
                                                id="invoice"
                                                name="invoice"
                                                v-model="shipment.invoice"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.invoice">{{ serverErrors.invoice[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="materialNumber" label="Material Number">
                                        <validation-provider #default="{ errors }" name="Material Number" rules="">
                                            <b-form-input
                                                id="materialNumber"
                                                name="materialNumber"
                                                v-model="shipment.materialNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.materialNumber">{{ serverErrors.materialNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="blNumber" label="B/L Number">
                                        <validation-provider #default="{ errors }" name="B/L Number" rules="">
                                            <b-form-input
                                                id="blNumber"
                                                name="blNumber"
                                                v-model="shipment.blNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.blNumber">{{ serverErrors.blNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <b-form-group label-for="blDate" label="B/L Date">
                                        <validation-provider #default="{ errors }" name="B/L Date" rules="">
                                            <b-form-input
                                                id="blDate"
                                                name="blDate"
                                                v-model="shipment.blDate"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.blDate">{{ serverErrors.blDate[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="bookingNumber" label="Booking Number">
                                        <validation-provider #default="{ errors }" name="Booking Number" rules="">
                                            <b-form-input
                                                id="bookingNumber"
                                                name="bookingNumber"
                                                v-model="shipment.bookingNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.bookingNumber">{{ serverErrors.bookingNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                    md="5"
                                >
                                    <b-form-group label-for="vesselImoNumber" label="Vessel IMO #">
                                        <validation-provider #default="{ errors }" name="Vessel IMO #" rules="">
                                            <b-form-input
                                                id="vesselImoNumber"
                                                name="etd"
                                                v-model="shipment.vesselImoNumber"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.vesselImoNumber">{{ serverErrors.vesselImoNumber[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="7"
                                >
                                    <label for="transit_id">Transit Port</label>
                                    <b-form-select
                                        id="transit_id"
                                        name="transit_id"
                                        size="sm"
                                        v-model="shipment.transit_id"
                                    >
                                        <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.code.toUpperCase() }}
                                        </b-form-select-option>
                                        <b-form-select-option
                                            v-for="port in ports"
                                            :key="port.id"
                                            :value="port.id"
                                            v-if="!port.country.code"
                                        >
                                            {{ port.name }}, {{ port.country.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <small class="text-danger" v-if="serverErrors && serverErrors.transit_id">{{ serverErrors.transit_id[0] }}</small>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group label-for="packingMaterial" label="Packing Material">
                                        <validation-provider #default="{ errors }" name="Packing Material" rules="">
                                            <b-form-textarea
                                                id="packingMaterial"
                                                name="packingMaterial"
                                                v-model="shipment.packingMaterial"
                                                :state="errors.length > 0 ? false : null"
                                                size="sm"
                                                rows="3"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.packingMaterial">{{ serverErrors.packingMaterial[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </tab-content>
                    </form-wizard>
                </div>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select
                            size="sm"
                            v-model="shipment.season_id"
                        >
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.value }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card-body>
            <!-- {{ shipment }} -->
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(container)="data">
                    {{ data.item.container.number }}
                </template>
                <template #cell(shipment)="data">
                    <span v-if="data.item.shipment.internalNumber">{{ data.item.shipment.internalNumber }}</span>
                    <span v-else>{{ data.item.shipment.number }}</span>
                </template>
                <template #cell(transactionDate)="data">
                    {{ data.item.transactionDate | moment('DD/MM/YYYY') }}
                </template>
                <template #cell(consignee)="data">
                    <span v-if="data.item.consignee">
                        {{ data.item.consignee.name }}
                    </span>
                </template>
                <template #cell(cartons)="data">
                    {{ data.item.cartons.toLocaleString() }}
                </template>
                <template #cell(tare)="data">
                    {{ data.item.tare.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>
                <template #cell(net)="data">
                    {{ data.item.net.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>
                <template #cell(gross)="data">
                    {{ data.item.gross.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup, BFormDatepicker,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormTextarea,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import shipmentsStoreModule from '@/views/cromis/shipping/shipments/shipmentsStoreModule'
    import useShipmentsList from '@/views/cromis/shipping/shipments/useShipmentsList'
    import moment from 'moment'
    import { useToast } from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {
        props: {},
        components: {
            FormWizard, TabContent,
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup, BFormDatepicker,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormTextarea,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const toast = useToast()
            const serverErrors = ref({})
            const saving = ref(false)
            const uploading = ref(false)
            const dataForm = ref(null)
            const myModal = ref(null)

            const seasons = ref([{ id: null, value: 'Select...' }])
            const companies = ref([])
            const ports = ref([])

            const shipment = ref({
                id: null,
            })
            
            const CROMIS_STORE_MODULE_NAME = 'cromis-shipment'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, shipmentsStoreModule)

            // UnRegister on leave
            onUnmounted(async () => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-shipment/seasons')
                        .then(response => {
                            seasons.value = response.data.seasons
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-shipment/companies')
                        .then(response => {
                            companies.value = response.data.companies
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-shipment/ports')
                        .then(response => {
                            ports.value = response.data.ports
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
            })

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
            } = useShipmentsList()

            const isFormValid = async () => {
                let isValid = true 
                // await dataForm.value.validate().then(success => {
                //     isValid = success
                // })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()

                serverErrors.value = null
                // Handle form submit
                if (shipment.value.id === null || shipment.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(shipment.value)
            }

            const invokeUpdateForm = (item) => {
                serverErrors.value = null
                saving.value = false

                shipment.value = {
                    id: item.id,
                    number: item.number,
                    internalNumber: item.internalNumber,
                    cartons: item.cartons.toLocaleString(),
                    tare: item.tare,
                    cases: null,
                    containerCases: null,
                    line: item.line,
                    grade: item.grade,
                    poNumber: item.poNumber,
                    mopiNumber: item.mopiNumber,
                    instruction: item.instruction,
                    contract: item.contract,
                    batch: item.batch,
                    description: item.description,
                    shipper_id: item.shipper? item.shipper.id : null,
                    consignee_id: item.consignee? item.consignee.id : null,
                    source_id: item.source_id,
                    destination_id: item.destination_id,
                    transit_id: item.transit_id,
                    vessel: item.vessel,
                    voyage: item.voyage,
                    etd: item.etd,
                    eta: item.eta,
                    revisedEta: item.revisedEta,
                    hsCode: item.hsCode,
                    tare: item.tare,
                    invoice: item.invoice,
                    materialNumber: item.materialNumber,
                    blNumber: item.blNumber,
                    blDate: item.blDate,
                    bookingNumber: item.bookingNumber,
                    packingMaterial: item.packingMaterial,
                    vesselImoNumber: item.vesselImoNumber,
                    fumigation: item.fumigation? item.fumigation.id: null,
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                serverErrors.value = null
                saving.value = false

                port.value = {
                    id: null,
                    name: null,
                    country_id: null,
                }

                myModal.value.show()
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-shipment/create', port.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Port registered successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-shipment/update', { id: shipment.value.id, data: shipment.value })
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to port ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 2000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-shipment/remove', id)
                            .then(response => {
                                refetch()
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: true,
                                })
                            })
            }

            return {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                dataForm,
                myModal,
                shipment,
                seasons,
                companies,
                ports,

                refetch,
                invokeCreateForm,
                invokeUpdateForm,
                isFormValid,
                submit,
                serverErrors,
                saving,
                uploading,
                handleCreate,
                handleUpdate,
                remove,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>